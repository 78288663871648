import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

const swiper = new Swiper('#hero-slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    autoplay: {
        delay: 2000,
    },
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
});
